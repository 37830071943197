import axios from "axios";
import { ValidationResult } from "../types/rni";
import ErrorFormatter, { ErrorClass } from "../utils/errorFormat";
import packageInfo from "../../package.json";

type LoginResponse = {
  success: boolean;
  access_code?: string;
  panel_id?: number;
  panelist_id?: number;
  platform_id?: number;
  has_agreed_terms?: boolean;
  require_instructions?: boolean;
  error?: string;
  error_code?: number;
};
export type LoginSuccess = {
  accessCode: string;
  panelistId: number;
  panelist: string;
};
export type LoginFailure = string;

type DetailsResponse = {
  success?: boolean;
  panelist_id?: number;
  reference?: string;
  access_code?: string;
  has_agreed_terms?: boolean;
  require_instructions?: boolean;
  camera_id?: number;
  role_name?: string;
  camera_type?: string;
  camera_type_id?: string;
  camera_status?: string;
  camera_status_id?: string;
  software?: string;
  software_id?: string;
  panel_id?: number;
  panel_name?: string;
  panel_paragraph?: string;
  panel_url?: string;
  panel_email?: string;
  panel_phonenumber?: string;
  panel_terms_and_conditions_url?: string;
  panel_privacy_url?: string;
  panel_faq_url?: string;
  panel_image_url?: string;
  panel_image_small_url?: string;
  panel_default_language?: string;
  streak_level?: number;
  today_has_done_streak?: boolean;
  today_session_count?: number;
  today_earnings?: number;
  messages?: string[];
  panel_config_override?: boolean;
  broker_version_config?: object;
  viewability_version_config?: object;
  language_version_config?: object;
  max_accuracy?: number;
  max_precision?: number;
  max_gaze_duration?: number;
  error?: string;
  error_code?: number;
};

export type DetailSuccess = {
  maxAcc: number;
  maxPrec: number;
  brokerVersionConfig: object;
  viewabilityVersionConfig: object;
};
export type DetailFailure = string;

export const loginRequest = async (username: string, password: string) => {
  const { data } = await axios.post<LoginResponse>(
    `${process.env.PANEL_API}/panelist/login/`,
    {
      panelist_reference: username,
      panelist_pin: password,
      panel: "3",
      device: "extension",
    },
  );

  if (data.success && !!data.access_code && !!data.panelist_id) {
    const success: LoginSuccess = {
      accessCode: data.access_code,
      panelistId: data.panelist_id,
      panelist: username,
    };
    return success;
  }
  const failure = data.error
    ? data.error
    : ErrorFormatter.getError(ErrorClass.INCORRECT_PASSWORD);

  throw failure;
};

export const getPanelistDetails = async (
  accessCode: string,
  panelistId: number,
) => {
  const { data } = await axios.post<DetailsResponse>(
    `${process.env.PANEL_API}/panelist/${panelistId}/get_details/`,
    {
      access_code: accessCode,
      device: "extension",
    },
  );

  if (data.success) {
    const success: DetailSuccess = {
      maxAcc: Math.round(data.max_accuracy) || 300,
      maxPrec: Math.round(data.max_precision) || 300,
      brokerVersionConfig: data.broker_version_config || {},
      viewabilityVersionConfig: data.viewability_version_config || {},
    };

    return success;
  }

  const failure = data.error
    ? data.error
    : "Something went wrong fetching panelist details";

  throw failure;
};

export type GetSessionResponse = {
  tmp_session_id?: string;
  error?: string;
  error_code?: number;
};

export type GetSessionSuccess = {
  tmpSessionId: string;
};

// export type GetSessionFailure = {
//   error?: string;
//   errorCode?: number;
// }

export const getSessionRequest = async (
  panelist: string,
  maxAcc,
  maxPrec,
  brokerVersionConfig,
  viewabilityVersionConfig,
) => {
  const { data } = await axios.post<GetSessionResponse>(
    `${process.env.TRACKING_API}/panel-session`,
    {
      panelist,
      device: "extension",
      broker_config: {
        env: "web",
        version: packageInfo.version,
        maxAcc,
        maxPrec,
        brokerVersionConfig,
        viewabilityVersionConfig,
      },
    },
  );

  if (data.tmp_session_id) {
    const success: GetSessionSuccess = {
      tmpSessionId: data.tmp_session_id,
    };
    return success;
  }
  const failure: unknown = data;

  throw failure;
};

export const submitWildValidationRequest = async (
  valData: ValidationResult,
  panelist: string,
  panelistId: number,
  tmpSessionId: string,
  calibrationTime: number,
) => {
  const validContext = {
    panelist,
    panelist_id: panelistId,
    session: tmpSessionId, // backwards comp
    tmp_session_id: tmpSessionId,
    study_id: null,
    cell_id: null,
    step_id: null,
    steporder_id: null,
    timestamp: Date.now(),
    zoom: 100,
    browser_width: window.outerWidth,
    browser_height: window.outerHeight,
    browser_left: 0,
    browser_top: 0,
    viewport_width: document.documentElement.clientWidth,
    viewport_height: document.documentElement.clientHeight,
    screen_resolution_width: screen.width,
    screen_resolution_height: screen.height,
    accuracy: valData.validatedErrorCorrection._accuracy,
    accuracy_x: valData.validatedErrorCorrection._xAccuracy,
    accuracy_y: valData.validatedErrorCorrection._yAccuracy,
    raw_accuracy: null,
    raw_accuracy_x: null,
    raw_accuracy_y: null,
    precision: valData.validatedErrorCorrection._precision,
    precision_x: valData.validatedErrorCorrection._xPrecision,
    precision_y: valData.validatedErrorCorrection._yPrecision,
    hit50: 0,
    hit100: 0,
    hit200: 0,
    time_since_calibration: Math.abs(calibrationTime - Date.now()) / 1000,
    gaze_duration:
      valData.validatedErrorCorrection._duration /
      valData.validatedErrorCorrection._sampleCount /
      1000,
    frame_rate: valData.validatedErrorCorrection._misc.errorCorrectionFramerate,
  };

  await axios.post<GetSessionResponse>(
    `${process.env.TRACKING_API}/panel-wild-validation-result`,
    validContext,
  );
};

export const startWildRequest = async (
  accessCode: string,
  tmpSessionId: string,
  panelistId: number,
) => {
  await axios.post(
    `${process.env.PANEL_API}/panelist/${panelistId}/wild/start/`,
    {
      access_code: accessCode,
      tmp_session_id: tmpSessionId,
      device: "extension",
    },
  );
};

export const endWildRequest = async (
  accessCode: string,
  tmpSessionId: string,
  panelistId: number,
) => {
  await axios.post(
    `${process.env.PANEL_API}/panelist/${panelistId}/wild/end/`,
    {
      access_code: accessCode,
      tmp_session_id: tmpSessionId,
    },
  );
};

export const deleteDataRequest = async (
  access_code: string,
  reason_id: string,
  panelist_reference: string,
  panelistId?: number,
) => {
  const endpoint = panelistId
    ? `/panelist/${panelistId}/delete_request/`
    : "/delete_request/";

  const { data } = await axios.post(`${process.env.PANEL_API}${endpoint}`, {
    access_code,
    panelist_reference,
    reason_id,
  });

  return data;
};

export const getDataDeletionReasonList = async () => {
  const { data } = await axios.get(
    `${process.env.PANEL_API}/panelist/delete_reason_list/`,
  );
  return data;
};

export const contactRequest = async (
  accessCode: string,
  panelistId: number,
  message: string
) => {
  const endpoint = !!panelistId ? `${process.env.PANEL_API}/panelist/${panelistId}/submit_feedback` : `${process.env.PANEL_API}/submit_feedback`
  await axios.post(
    endpoint,
    {
      access_code: accessCode,
      message
    },
  );
};

// export const startWildRequest = (data, success, failure) =>
//   axios
//     .post(API_URL + '/panelist/' + data.panelistId + '/wild/start/', {
//       access_code: data.accessCode,
//       tmp_session_id: data.sessionId,
//       device: 'mobile',
//     })
//     .then((res) => {
//       if (res.status === 200) {
//         return res.data;
//       } else {
//         throw 'Unable to connect to server';
//       }
//     })
//     .then((_resData) => {
//       success();
//     })
//     .catch((err) => {
//       failure(err);
//     });

// endWildRequest = (data, success, failure) =>
//   axios
//     .post(API_URL + '/panelist/' + data.panelistId + '/wild/end/', {
//       access_code: data.accessCode,
//       tmp_session_id: data.sessionId,
//     })
//     .then((res) => {
//       if (res.status === 200) {
//         return res.data;
//       } else {
//         throw 'Unable to connect to server';
//       }
//     })
//     .then((_resData) => {
//       success();
//     })
//     .catch((err) => {
//       failure(err);
//     });
