import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { isChrome, isMobile } from "react-device-detect";

import { useAppSelector } from "./hooks/store";
import { FocusNavbar } from "./components/navbar/navbar";
import { SessionRoute } from "./components/session/session";
import InstallChrome from "./components/install/installChrome";
import InstallMobileApp from "./components/install/installMobileApp";
import Login from "./components/login/login";

const Root = () => {
  const isLoggedIn = useAppSelector((state) => state.auth.loggedIn);
  const { pathname } = useLocation();
  const isSessionRoute =
    Object.values(SessionRoute).includes(pathname as SessionRoute) &&
    isLoggedIn;

  const externalPaths = [
    "/sign-up",
    "/reset-password",
    "/data-deletion-request",
    "/contact",
  ];

  const getContent = () => {
    if (!isLoggedIn && !externalPaths.includes(pathname)) return <Login />;
    if (isSessionRoute && isMobile) return <InstallMobileApp />;

    if (isSessionRoute && !isChrome) return <InstallChrome />;

    return <Outlet />;
  };
  return (
    <div className="h-screen relative w-screen overflow-hidden">
      {isLoggedIn ? <FocusNavbar /> : null}
      <main className="light text-foreground bg-background relative h-main p-8 lg:px-16 overflow-y-auto">
        <div
          className={`${isSessionRoute && isChrome ? "" : "flex justify-center"}`}
        >
          <div
            className={`${isSessionRoute && isChrome ? "flex flex-col lg:flex-row gap-10 lg:gap-0" : "w-full md:w-3/4 lg:w-1/2"}`}
          >
            {getContent()}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Root;
