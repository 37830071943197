import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@nextui-org/react";
import { SessionRoute } from "../session";
import { SessionPositionContext } from "./types";

const SessionPosition = () => {
  const { broker, setPositionStatus } =
    useOutletContext<SessionPositionContext>();
  const navigate = useNavigate();
  const [score, setScore] = useState(0);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const cont = () => {
    broker.trackersController._videoView.config.colour = "rgba(0,0,0,0)";
    broker.stopEyePos();
    navigate(SessionRoute.CALIBRATION);
  };
  let throttle = Date.now();

  const goalCoordinates = {
    w: broker.trackersController.config.frameReader.width,
    h: broker.trackersController.config.frameReader.height,
    goalX: Math.round(broker.trackersController.config.frameReader.width / 2),
    goalY: Math.round(broker.trackersController.config.frameReader.height / 2),
  };
  useEffect(() => {
    broker.getEyePos((res) => {
      if (Date.now() <= throttle + 500) return;
      let xScore: number;
      let yScore: number;
      const xCenter = (res._right.out.x + res._left.out.x) / 2;
      const yCenter = (res._right.out.y + res._left.out.y) / 2;

      if (xCenter > goalCoordinates.goalX) {
        xScore = 100 - (xCenter / goalCoordinates.goalX - 1) * 100;
      } else {
        xScore = (xCenter / goalCoordinates.goalX) * 100;
      }

      if (yCenter > goalCoordinates.goalY) {
        yScore = 100 - (yCenter / goalCoordinates.goalY - 1) * 100;
      } else {
        yScore = (yCenter / goalCoordinates.goalY) * 100;
      }

      const lowestScore = () => {
        if (xScore > yScore) {
          return Math.round(yScore);
        }
        return Math.round(xScore);
      };

      const final = lowestScore();

      setPositionStatus(final);
      setScore(final);
      throttle = Date.now();
    });
  }, []);

  return (
    <>
      <p className="font-semibold mb-10">Step 2: Head Position</p>
      <p className="mb-10">Your head position is important.</p>
      <p className="mb-10">
        To use the data a head position score of 80 plus is needed.
      </p>
      <p className="mb-10">
        Throughout the study plase make sure your head is in view of the camera.
      </p>
      <Button
        color="secondary"
        onClick={cont}
        isDisabled={score < 80}
        ref={buttonRef}
      >
        Continue
      </Button>
    </>
  );
};

export default SessionPosition;
