import React from "react";
import Login from "../components/login/login";
import Session from "../components/session/session";
import { useAppSelector } from "../hooks/store";

const Home = () => {
  const loggedIn = useAppSelector((state) => state.auth.loggedIn);
  return loggedIn ? <Session /> : <Login />;
};

export default Home;
