import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import { Button } from "@nextui-org/react";
import { SessionSectionContext } from "../session";

const SessionCompletion = () => {
  const { broker, dispatch, errorHandle } =
    useOutletContext<SessionSectionContext>();
  const navigate = useNavigate();

  return (
    <>
      <p className="font-semibold mb-10">Session complete</p>
      <p className="mb-10">You earned 1000 points = £1</p>
      <Button color="secondary" onClick={() => navigate("/")}>
        Start another session
      </Button>
    </>
  );
};

export default SessionCompletion;
