/* eslint-disable react/no-unescaped-entities */
import React from "react";

const Terms = () => {
  return (
    <>
      <section className="mb-10">
        <h1 className="font-semibold mb-10 text-2xl">
          Focus Project (UK): Terms and Conditions
        </h1>
        <p className="font-semibold mb-10">Last updated: 1 March 2022</p>
        <p>
          These Terms and Conditions along with the Focus Project Privacy Policy
          (collectively the "Terms") govern your membership and participation in
          the Focus Project. The Focus Project is an online behavioural and
          market research project involving the use of eye tracking technology
          to explore how individuals view and interact with online media (the
          "Research").{" "}
        </p>
        <p>
          The Focus Project is run by Lumen Research Ltd ("Lumen" or "Us/We").
          Lumen Research Ltd is a company registered in England, company number
          8682432. Its registered office is at; Unit 215, 22 Highbury Grove,
          London N5 2EF. Its data protection registration reference is ZA130772.
          These Terms and Conditions also set out the terms of the agreement
          ("Agreement") between Lumen Research Ltd and you ("You"/"Member").
        </p>
        <p>
          To become a Member of the Research you must successfully complete an
          initial registration survey sent to you and install some software (the
          "Software") and, in some cases, an eye tracking device (the "Device").
          Each Member can then earn Rewards subject to compliance with these
          Terms. These Rewards will be administered by the market research
          opportunity provider through which you were recruited to participate
          (the "Panel Company").{" "}
        </p>
        <p>
          The Panel Company that will have presented the opportunity for you to
          participate in this research is Panelbase, a division of Dipsticks
          Research Limited, a company registered in England under company number
          3752827. Its registered office is at; The Mill, Hexham Business Park,
          Burn Lane, Hexham, Northumberland, NE46 3RU. Its data protection
          registration reference is Z8203709.
        </p>
      </section>
      <section className="mb-10">
        <p className="font-semibold mb-10">1. Agreement to Terms</p>
        <p>
          Please read this Agreement carefully before registering for the
          Research. By agreeing to participate in the Research, you acknowledge
          that you have agreed to be bound by this Agreement and the associated
          Privacy Policy. If you do not agree to the terms and conditions of
          this Agreement, you may not participate in the Research. We reserve
          the right to refuse or cancel your participation in the Research. All
          Members must be registered with the Panel Company in order to
          participate in the Research. Members must be over the age of 18.
        </p>
      </section>
      <section className="mb-10">
        <p className="font-semibold mb-10">2. Participation and Rewards</p>
        <p>
          You can earn Rewards by installing the Software (and, where
          applicable, the Device) and, browsing the Internet while the eye
          tracking is switched on, and taking part in activities, such as
          completing surveys or visiting specific websites.
        </p>
        <p className="mb-6">
          You will be invited to take part in activities relating to the
          Research via email, or through the Software (e.g., via a notification
          on your smartphone). Participation in each activity is voluntary. We
          may add or remove activities available from time to time.
        </p>
        <p className="font-semibold mb-6">
          Rewards for successful participation in the Research are as follows:
        </p>
        <p>
          You will earn Points by completing Browsing Sessions (browsing
          anywhere you like on the internet) and by completing Tasks (viewing
          items, answering questions, or browsing specific sites that are
          selected for you).
        </p>
        <p>
          Focus project has its own points currency: 1,000 points is worth £1.
          Points will be awarded as follows:{" "}
        </p>
        <ul className="mb-6">
          <li>Sign-up reward: Completing the Welcome Task = 1,000 points</li>
          <li>
            Completing a Browsing Session: Browsing anywhere on the internet for
            10 minutes = 100 Points (max six per day)
          </li>
          <li>
            Browsing Activity Daily Bonus: Bonus for completing two or more
            Browsing Sessions in a single day = 200 Points
          </li>
          <li>
            Streak Bonus: Bonus for completing a Streak i.e., at least one
            Browsing Session a day over 5 consecutive days = 1,000 Points
          </li>
          <li>Completing a Task: Variable</li>
        </ul>
        <p className="font-semibold mb-6">What is a Browsing Session?</p>
        <p className="mb-6">
          A Browsing Session is a 10 minute period of time spent browsing
          anywhere you like on the internet whilst the Focus Project eye
          tracking software is turned on. You may be asked to 'calibrate' your
          eyes e.g., by watching a dot moving around your screen at the start
          and/or the end of each Browsing Session.
        </p>
        <p className="font-semibold mb-6">What is a Task?</p>
        <p className="mb-6">
          A Task will be explained before your launch it but will typically
          involve viewing items, answering questions, or browsing specific sites
          that are selected for you. The invitation to participate in a task may
          provide, or third parties may provide, links to other websites or
          resources. As we have no control over such websites and resources you
          agree that links to third party websites are provided solely for your
          convenience and we have no responsibility for the availability of such
          external sites or resources and shall not be liable for the content
          found at such sites or resources. If you decide to access any
          third-party websites linked to the Research, you do so entirely at
          your own risk.
        </p>
        <p className="font-semibold mb-6">
          What is a Browsing Activity Daily Bonus?
        </p>
        <p className="mb-6">
          You can earn 200 extra points as a Browsing Activity Daily Bonus by
          completing a certain number of Browsing Sessions in a single day. This
          is defined as the 24-hour period that corresponds to a single calendar
          day (i.e., between 00:00 and 23:59) across the UTC time zone. You must
          complete the required number of Browsing Sessions within this time
          period to earn the Browsing Activity Daily Bonus. For example, if you
          need to complete at least 2 sessions within a day to earn the Browsing
          Activity Daily Bonus, you need to complete both the sessions on the
          same calendar day. Completing them within a 24-hour period will not
          activate the bonus if they are completed on different calendar days.
        </p>
        <p className="font-semibold mb-6">What is a Streak Bonus?</p>
        <p className="mb-6">
          You can earn 1,000 extra points as a Streak Bonus by completing
          Browsing Sessions over several consecutive calendar days. Your Streak
          shows whether you have completed Browsing Sessions over consecutive
          days. Your Streak will build up each day and you will receive a Streak
          Bonus if you complete your Streak. This rewards you for regular usage.
          If you do not complete a Browsing Session on any given day, your
          streak will re-set to zero.
        </p>
        <p className="font-semibold mb-6">Monthly Prize Draw</p>
        <p className="mb-6">
          The Focus Project includes a monthly prize draw with a total prize
          fund of £250. Following the end of each calendar month, we will draw
          winners at random in order to allocate the following prizes:
        </p>
        <table
          style={{
            width: "100%",
            borderCollapse: "separate",
            borderSpacing: "0px 1em",
          }}
          className="mb-6"
        >
          <thead>
            <tr>
              <th aria-label="empty" />
              <th style={{ textAlign: "left" }}>Quantity</th>
              <th style={{ textAlign: "left" }}>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1st prize</td>
              <td>1</td>
              <td>£50.00</td>
            </tr>
            <tr>
              <td>2nd prize</td>
              <td>2</td>
              <td>£25.00</td>
            </tr>
            <tr>
              <td>3rd prize</td>
              <td>5</td>
              <td>£10.00</td>
            </tr>
            <tr>
              <td>4th prize</td>
              <td>10</td>
              <td>£5.00</td>
            </tr>
            <tr>
              <td>5th prize</td>
              <td>25</td>
              <td>£2.00</td>
            </tr>
          </tbody>
        </table>
        <p>
          In total, there are 43 winners each month and all winners are notified
          by email of their winnings, which are credited directly to their
          Panelbase account within 7 days of the end of the calendar month.
        </p>
        <p className="mb-6">
          Every time you complete a Task or a Browsing Session you will receive
          an additional entry into the monthly prize draw.
        </p>
        <p className="font-semibold mb-6">Eye tracking device return</p>
        <p>
          If you have been issued an eye tracking Device, once you have been
          notified that the Research has finished, and you have successfully
          returned the Device in the packaging provided, we will send you a
          final Unsubscribe survey related to the Research. It is important to
          keep the box/packaging in a safe place, so it can be used at the end
          of the Research to return the Device.
        </p>
        <p className="mb-6">
          Once you have received the email notifying you that the Research has
          finished, and you have also received the packaging in which to return
          the Device, you will need to return the Device by post within 14 days.
        </p>
        <p className="font-semibold mb-6">Administration of Rewards</p>
        <p>
          Your points will be converted automatically into their £ value and
          deposited into your Panelbase account at the end of every month.
        </p>
        <p>
          The Rewards will be administered via your Panel Company account and
          will be subject to their standard terms and conditions in relation to
          Rewards accrual and payments. You will earn Rewards by accumulating
          Points for carrying out certain activities. These Points can be
          redeemed in your Panel Company account. Rewards can only be earned
          through successfully participating in the Research as set out in these
          Terms. Any Rewards awarded through participation in the Research but
          not earned in accordance with these Terms may be deducted from your
          Panel Company account.
        </p>
        <p>
          We reserve the right to change the number of Points that can be earned
          for any type of participation in the Focus Project at any time.
          Rewards associated with the Research may be amended, substituted or
          withdrawn by us at any time and without notice.
        </p>
      </section>
      <section className="mb-10">
        <p className="font-semibold mb-10">3. Third parties</p>
        <p>
          We may use third party sub-contractors to operate certain activities
          relating to the Research. These Terms apply to such activities and/or
          websites operated for the purposes of such activities. These third
          party sub-contractors may have additional terms and conditions which
          will be made clear to you prior to participating in the applicable
          activity. The Software you download is provided on behalf of and
          remains the property of Lumen Research Ltd throughout the period of
          the Research.
        </p>
        <p>
          The Research is managed by us and we follow the Market Research
          Society Code of Conduct. When participating in any activity, you may
          have access to content belonging to us, our subcontractors or any
          other third party ("Proprietary Content"). Such content shall remain
          the sole and exclusive property of such party, and in no event shall
          you obtain or receive any right, title and/or interest in such
          Proprietary Content.
        </p>
      </section>
      <section className="mb-10">
        <p className="font-semibold mb-10">
          4. Data collected through the Research
        </p>
        <p>
          The collection of data through the Software is governed by the
          accompanying 'Focus Project Privacy Policy' document.
        </p>
        <p>
          You represent and warrant that any data you provide in connection with
          your use of any Software and, where applicable, the Device is provided
          is true, accurate and complete, and that you will maintain and update
          such data as needed, such that the data remains true, accurate and
          complete. You acknowledge that the primary purpose of the Software is
          for Lumen to collect and hold data for the purposes as described in
          the Focus Project Privacy Policy document.
        </p>
      </section>
      <section className="mb-10">
        <p className="font-semibold mb-10">5. Software; restrictions on use</p>
        <p>
          The Software issued is licensed to end users. Subject to this
          Agreement, we hereby agree to permit you, for the period of your
          participation, and on a non-exclusive, revocable, non-transferable,
          non-sublicensable, limited basis, to install the Software and, where
          applicable, the Device, on a device you own or control with respect to
          which you represent and warrant that you have all necessary rights and
          permissions for such installation and access and use thereafter (your
          "Equipment"); provided that your installation and use of the Software
          is solely (a) for your personal use in connection with your
          participation in the Research; and (b) in accordance with each of the
          restrictions and limitations set forth in this Agreement. If you fail
          to comply with any of the Terms set forth in this Agreement, this
          Agreement will automatically terminate, whereupon you will immediately
          (i) cease using the Software and (ii) remove (i.e., uninstall and
          delete) the Software. You are solely responsible for any violation of
          any applicable laws that results from your failure to abide by the
          Terms of this Agreement.
        </p>
        <p>
          The installed software will be updated on your Equipment when updates
          become available.
        </p>
      </section>
      <section className="mb-10">
        <p className="font-semibold mb-10">6. Rules of conduct</p>
        <p>
          While using the Software (and, where applicable, the Device) you agree
          to comply with all applicable laws, rules and regulations. Your use of
          the Software (and, where applicable, the Device) is conditional on
          your compliance with the rules of conduct in this section and
          throughout these Terms. You agree not to: (i) copy, modify, adapt,
          make available, rent, lease, timeshare, create a derivative work of,
          reverse engineer or attempt to sell or otherwise transfer any right in
          the Proprietary Content; (ii) use the Proprietary Content for any
          purpose other than the purpose of the activity as specified by Focus
          Project; (iii) use spiders, robots or other automated data mining
          techniques to catalogue, download, store, or otherwise reproduce or
          distribute Proprietary Content, or to manipulate the results of any
          survey or any other activity; (iv) take any action to interfere with
          the Research or an individual's participation in the Research
          including, without limitation, by overloading, "flooding", "mail
          bombing" or "crashing" the Focus Project website and the data storage
          system Focus Project use through Amazon Web Services (v) send, to us,
          and/or to or from the Focus Project website, any illegal, deceptive,
          or harmful code, including, without limitation, any virus, spyware,
          adware, or any other harmful code; (vi) open, use, or maintain more
          than one membership account within the Focus Project Research; (vii)
          forge or mask your identity; (vii) frame a portion(s) of the Focus
          Project website within another website or alter the appearance of the
          Focus Project website; (viii) establish links from any other website
          to any page of, on, or located within the Focus Project website; (ix)
          post or transmit any threatening, libellous, defamatory, obscene,
          scandalous, or inflammatory material or content or any material or
          content that could otherwise violate applicable laws; and (x)
          intentionally or wilfully submit falsified data or commit any other
          fraudulent act(s), including without limitation attempt to earn
          Rewards or redeem Rewards in any manner conflicting with the intent of
          these Terms.
        </p>
        <p>
          All rights not expressly granted to you hereunder are reserved to us
          and our licensors. You agree that we have no obligation under this
          Agreement or otherwise to correct any bugs, defects, or errors in the
          Software or to otherwise support, maintain, improve, modify, upgrade,
          update or enhance the Software. Support will be provided at our
          discretion; we will not be obliged to provide assistance to you or
          your designees in the use of the Software in any manner.
        </p>
        <p>
          You are solely liable and responsible for all content, materials,
          information, and comments, including, without limitation, all
          pictures, videos, and/or other related or similar content ("User
          Content"), that you use, upload, post or submit in connection with the
          Focus Project Research. You are solely responsible for all third-party
          approvals, consents, and/or authorizations required for, or in
          connection with, User Content. By using, uploading, posting, or
          submitting User Content in connection with the Focus Project Research,
          you hereby grant to us a perpetual, irrevocable, unlimited,
          transferrable, sub-licensable, world-wide, royalty free, right and
          license to edit, copy, transmit, publish, display, create derivative
          works of, reproduce, modify, distribute, and/or otherwise use your
          User Content.
        </p>
        <p>
          We are not liable for any damage to your Equipment including but not
          limited to as a result of your downloading and using the Software
          (and, where applicable, the Device).
        </p>
        <p>
          We are not liable for any additional data charges incurred by you as a
          result of the data collected by the Software (and, where applicable,
          the Device) through your participation in the Research.
        </p>
        <p>
          All communications and submissions of User Content, by you to us shall
          be treated as nonconfidential and non-proprietary information. You
          agree that we may use any such communications and User Content for any
          reason.
        </p>
        <p>
          The Focus Project Privacy Policy (which forms part of the Terms) sets
          out what information on Members we will use, how this information will
          be used and who it may be shared with.
        </p>
        <p>
          We may make changes to the Terms at any time, and we shall use
          reasonable efforts to inform Members of such changes and participating
          in Research activities will constitute acceptance of revised Terms. We
          may suspend or terminate the Research and will provide notice where
          possible. We may suspend or terminate your Research account at any
          time if you breach these Terms. You agree to indemnify, defend and
          hold us, our subcontractors, and our respective parents, affiliates
          and subsidiaries harmless from and against any and all claims,
          liabilities, losses, judgments, awards, fines, penalties and costs
          and/or expenses of any kind, including, without limitation, reasonable
          legal fees and court costs, arising out of, resulting from, in
          connection with, or caused by, whether directly or indirectly,: (i)
          your breach or violation of the Terms; and/or (ii) any actions taken
          by any individual accessing your account, with or without your
          consent, including, but not limited to, disclosing or changing any
          account access controls and any actions in connection with or
          regarding Rewards, including, without limitation, the redemption of
          Rewards.
        </p>
      </section>
      <section className="mb-10">
        <p className="font-semibold mb-10">7. Contact us</p>
        <p>
          You can find help and support by visiting the Focus Project website
          (www.focusproject.co.uk) or by writing to the Panelbase support desk
          (support@panelbase.zendesk.com) or by calling Panelbase on their UK
          freephone number 0800 195 8492 and quoting the Focus Project. We may
          record or monitor calls to check the quality of our services or for
          training, audit or security purposes.
        </p>
      </section>
      <section className="mb-10">
        <p className="font-semibold mb-10">8. Eligibility</p>
        <p>This Research is for residents of the UK only.</p>
        <p>
          You must provide your Panel Company with your correct name, email,
          address and telephone number and you must notify them of any changes
          to these details. By providing these details you agree that they can
          be used to contact you, if necessary, about the Focus Project
          Research. Your registration details may be checked to ensure they
          comply with these Terms. If registration details do not comply with
          these Terms we may, at our discretion, ask you to update your
          registration status or we may delete your registration without notice.
        </p>
        <p>
          You will be emailed to notify you that you have been accepted to take
          part in the Research. You will also be sent reminder emails to remind
          you to start, or continue, participating.
        </p>
        <p>
          You have the right to unsubscribe from Focus Project Research at any
          time. If you do not take part in the Focus Project for an extended
          period of time you will be automatically unsubscribed.{" "}
        </p>
      </section>
      <section className="mb-10">
        <p className="font-semibold mb-10">9. Links/ tasks</p>
        <p>
          You agree that your use of third-party websites/tasks and resources
          and any content, information, data, advertising, products, services,
          or other materials on or available through such websites and resources
          is at your own risk and is subject to the terms and conditions of use
          applicable to such websites and resources.
        </p>
      </section>
      <section className="mb-10">
        <p className="font-semibold mb-10">10. Disclaimer</p>
        <p>
          We and our respective directors, officers, employees, agents,
          representatives, licensors and providers make no representation or
          warranty or condition (a) that the Software (and, where applicable,
          the Device) will be available on a timely basis, or that access to the
          Software will be uninterrupted or secure; (b) that defects or errors
          will be corrected; or (c) that the Software or the servers or networks
          through which the Software are made available are secure or free of
          viruses or other harmful components. We shall have no obligation to
          furnish any maintenance and/or support services with respect to the
          Software.
        </p>
      </section>
      <section className="mb-10">
        <p className="font-semibold mb-10">11. Limitation of liability</p>
        <p>
          To the fullest extent permissible under applicable law, you agree that
          we and our respective directors, officers, employees, agents,
          representatives, licensors and providers shall have no liability for
          any or all losses of any kind resulting from your use or access of the
          Software (and, where applicable, the Device), your use of or inability
          to use the Software (and, where applicable, the Device), any
          information or materials made available on or through the Software,
          and/or as otherwise relating to the Agreement, including your losses
          or injuries arising from Focus Project acts or omissions under the
          Agreement, regardless of the nature of the legal, equitable or
          statutory right claimed to have been violated.
        </p>
        <p>
          To the fullest extent permissible under applicable law, your sole and
          exclusive remedy for dissatisfaction with the Software (and, where
          applicable, the Device) is to uninstall and stop using the Software
          and/or Device.
        </p>
        <p>
          In no event shall any of our entities be liable to you for any
          incidental, indirect, consequential, statutory, exemplary, punitive or
          special damages, loss of revenue, loss of opportunity, loss of
          reputation, loss of use, loss of data, loss of other intangibles, or
          unauthorized interception of data collected by the Software by third
          parties, whether foreseeable or not, however caused, and under any
          contract, tort (including without limitation negligence), strict
          liability or other theory, even if advised in advance of the
          possibility that such damages might arise. nothing in these terms
          shall exclude or limit our liability for death or personal injury
          caused by our negligence
        </p>
      </section>
      <section className="mb-10">
        <p className="font-semibold mb-10">12. Termination and enforcement</p>
        <p>
          This Agreement is effective until terminated. You may terminate this
          Agreement at any time by removing the Software from your Equipment and
          by, where applicable, returning the Device. You agree that we, at our
          sole discretion, may suspend, restrict or terminate your access to or
          use of the Software (and, where applicable, the Device), at any time
          and for any reason, including without limitation if we believe that
          you have violated or acted inconsistently with the terms of this
          Agreement, subject to any restrictions placed on our exercise of such
          rights under applicable law. Your rights under this Agreement will
          also terminate automatically without notice from us upon the
          termination of the Research.
        </p>
        <p>
          Upon any such termination, your right to use the Software (and, where
          applicable, the Device) will immediately cease, and you agree to
          immediately remove them from your Equipment and to irrevocably destroy
          all copies of the Software or such other instructions as Focus Project
          may provide. Where applicable, you will also be required to return the
          Device in your possession to the Focus Project Team, but you will not
          be required to pay the associated postage costs which will be borne by
          the Focus Project. To terminate please email
          support@panelbase.zendesk.com and instructions for returning the
          device will then be provided. We reserve the right to take steps that
          we believe are necessary or appropriate to enforce and/or verify
          compliance with this Agreement (including without limitation in
          connection with any legal process relating to your use of the Software
          and/or a third-party claim that your use of the Software and/or the
          Device are unlawful or violates such third party's rights).
        </p>
      </section>
      <section className="mb-10">
        <p className="font-semibold mb-10">13. Entire agreement</p>
        <p>
          Each party to this Agreement acknowledges that, in entering into this
          Agreement, it has not relied on, and (in the absence of fraud) shall
          have no right or remedy in respect of, any statement, representation,
          assurance or warranty (whether made negligently or innocently) other
          than as expressly set out in this Agreement. This Agreement is the
          entire agreement between you and us with regards to the subject matter
          hereof and supersedes any and all prior communications, warranties,
          indemnities, undertakings, conditions, understanding, commitments or
          agreements between the parties with respect thereto. This Section does
          not exclude liability of either party for fraudulent
          misrepresentation.
        </p>
      </section>
      <section className="mb-10">
        <p className="font-semibold mb-10">14. Device Safety and Compliance</p>
        <p className="mb-6">
          If you are one of the Focus Project members that has been asked to
          install a specific piece of hardware for activating the eye tracking
          (the Device), you agree you have read and understood the information
          below:
        </p>
        <p className="font-semibold mb-6">Epilepsy Warning</p>
        <p className="mb-6">
          Some people with Photosensitive Epilepsy are susceptible to epileptic
          seizures or loss of consciousness when exposed to certain flashing
          lights or light patterns in everyday life. This may happen even if the
          person has no medical history of epilepsy or has never had any
          epileptic seizures.
          <br />A person with Photosensitive Epilepsy would also be likely to
          have problems with TV screens, some arcade games and flickering
          fluorescent bulbs. Such people may have a seizure when watching
          certain images or patterns on a monitor, or even when exposed to the
          light sources of an eye tracking device. It is estimated that around
          3-5% of people with epilepsy have this type of Photosensitive
          Epilepsy. Most people with Photosensitive Epilepsy experience a type
          of "aura" or feel odd sensations before the seizure occurs. If you
          feel odd during use, move you eye away from the Device.
        </p>
        <p className="font-semibold mb-6">Infrared Warning</p>
        <p className="mb-6">
          When activated, the Device emits pulsed infrared (IR) light. Certain
          medical devices are susceptible to disturbance from the IR light
          and/or radiation. Do not use the Device when in the vicinity of such
          susceptible medical devices as their accuracy or proper functionality
          could be inhibited.
        </p>
        <p className="font-semibold mb-6">Magnetic Field warning</p>
        <p className="mb-6">
          The Device magnetic mounting contains magnets. Magnetic fields may
          interfere with the function of cardiac pacemakers and implantable
          cardioverterdefribrillators. As a general rule, maintain a minimum
          distance of 6 inches (15 centimetres) between any item with magnets
          and your heart device.
        </p>
        <p className="font-semibold mb-6">Child Safety</p>
        <p className="mb-6">
          The Device is an advanced computer system and electronic device not
          suitable for children.
        </p>
        <p className="font-semibold mb-6">Compatibility with other devices</p>
        <p className="mb-6">
          The Device complies with Part 15 of the Federal Communications
          Commission (FCC) U.S.A. rules. Operation is subject to the following 2
          conditions: (1) This device may not cause harmful interference, and
          (2) this device must accept any interference received, including
          interference that may cause undesired operation.
          <br />
          In addition, the Device will use some of your computer's battery life.
        </p>
        <p className="font-semibold mb-6">Modifications</p>
        <p>
          Modifications are not permitted and shall void the users authority to
          operate the Device under FCC rules.
        </p>
        <p>
          The Device has been tested and found to comply with limits for a Class
          B digital device, pursuant to part 15 of the FCC rules. The limits are
          designed to provide reasonable protection against harmful interference
          in a residential installation. The Device generates, uses and can
          radiate radio frequency energy and, if not installed and used in
          accordance with the instructions, may cause harmful interference to
          radio communications. However, there is no guarantee that interference
          will not occur in a particular installation. If this Device does cause
          harmful interference to radio or television reception, which can be
          determined by turning the Device off and on, the user is encouraged to
          try to correct the interference by one or more of the following
          measures:
        </p>
        <ul>
          <li>Reorient or relocate the receiving antenna</li>
          <li>Increase the separation between Device and receiver</li>
          <li>
            Connect the Device to a wall outlet on a circuit different from that
            which the receiver is connected
          </li>
        </ul>
      </section>
      <section>
        <p className="font-semibold mb-10">15. Miscellaneous</p>
        <p>
          If any provision of this Agreement shall be deemed to be unlawful,
          void or for any reason unenforceable, then that provision shall be
          deemed severable from these terms and shall not affect the validity
          and enforceability of any remaining provisions. This Agreement does
          not, and shall not be construed to, create any partnership, joint
          venture, employer-employee, agency or franchise or franchisee
          relationship between you and us. To the extent permitted by applicable
          law, a person who is not party to this Agreement may not enforce any
          of its provisions. You may not assign, transfer or sublicense any or
          all of your rights or obligations under this Agreement without our
          express prior written consent. We may assign, transfer, share or
          divide any or all of our rights or obligations under this Agreement
          without restriction. No waiver by either party of any breach or
          default hereunder will be deemed to be a waiver of any preceding or
          subsequent breach or default. Any heading, caption or section title
          contained herein is inserted only as a matter of convenience, and in
          no way defines or explains any section or provision hereof. Notices to
          you may be made by posting a notice (or a link to a notice) to the
          Software, by email, or by regular mail, at our discretion. Without
          limitation, you agree that a printed version of this Agreement and of
          any notice given in electronic form shall be admissible in judicial or
          administrative proceedings based upon or relating to this Agreement to
          the same extent and subject to the same conditions as other business
          documents and records originally generated and maintained in printed
          form. Nothing in this Agreement, express or implied, is intended to or
          shall confer on any person (other than the parties and their
          respective successors or permitted assigns) any rights, remedies,
          obligations or liabilities.
        </p>
      </section>
    </>
  );
};

export default Terms;
