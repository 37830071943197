import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@nextui-org/react";
import { useAppSelector } from "@/hooks/store";
import { submitWildValidationRequest } from "../../../api";
import { ValidationResult } from "../../../types/rni";
import { SessionRoute } from "../session";
import ErrorFormatter, { ErrorClass } from "../../../utils/errorFormat";
import { SessionValidationContext } from "./types";

const SessionValidation = () => {
  const {
    broker,
    validationSessionDetails,
    validationRef,
    trackerRef,
    errorHandle,
    setValidationStatus,
  } = useOutletContext<SessionValidationContext>();
  const navigate = useNavigate();
  const maxAcc = useAppSelector((state) => state.auth.maxAcc);
  const validate = async () => {
    // TODO: SCORE VALIDATION
    try {
      const div = validationRef.current;
      const trackerDiv = trackerRef.current;
      if (!div || !trackerDiv)
        throw ErrorFormatter.getError(ErrorClass.VALIDATION_DIV);
      broker.trackersController._videoView.releaseDivElement();
      const validation: ValidationResult = await broker.validate(div, 50000);
      if (setValidationStatus) {
        setValidationStatus(validation.validatedErrorCorrection._accuracy);
      }
      if (
        validation.validatedErrorCorrection._accuracy > maxAcc ||
        validation.validatedErrorCorrection._accuracy > maxAcc
      )
        throw ErrorClass.VALIDATION_FAIL;
      await submitWildValidationRequest(
        validation,
        validationSessionDetails.panelist,
        validationSessionDetails.panelistId,
        validationSessionDetails.tmpSessionId,
        validationSessionDetails.calibrationTime,
      );
      broker.trackersController.setVideoDiv(trackerDiv);
      broker.trackersController._videoView.render();
      cont();
    } catch (e) {
      const div = validationRef.current;
      if (div) {
        div.style.display = "none";
      }
      broker.turnOffCamera();
      if (e === ErrorClass.VALIDATION_FAIL) {
        const detail: ErrorDetail = {
          fmt: ErrorFormatter.getError(e),
          action: () => {
            broker.trackersController._detectorCalibrator.reset();
            broker.trackersController._gazeValidator.reset();
            broker.turnOnCamera();
            navigate(SessionRoute.CALIBRATION);
          },
          route: SessionRoute.ERROR,
        };
        errorHandle(detail);
      } else {
        errorHandle(e);
      }
    }
  };
  const cont = () => {
    navigate(SessionRoute.EXTERNAL);
  };
  return (
    <>
      <p className="font-semibold mb-10">Step 4: Validation</p>
      <p className="mb-10">
        On the next page follow the green dot with your eyes.
      </p>
      <p className="mb-10">Try to keep your head still.</p>
      <Button color="secondary" onClick={validate}>
        Continue
      </Button>
    </>
  );
};

export default SessionValidation;
