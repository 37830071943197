import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
} from "@nextui-org/react";
import { Cog8ToothIcon } from "@heroicons/react/24/outline";

import { useAppDispatch } from "../../hooks/store";
import { logOut } from "../../store/reducers/auth";

interface ISettingsMenuItem {
  title: string;
  href: string;
  onClick?: () => void;
}

const SettingsMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuItems: ISettingsMenuItem[] = [
    {
      title: "Privacy Policy",
      href: "/privacy-policy",
    },
    {
      title: "Terms and Conditions",
      href: "/terms-and-conditions",
    },
    {
      title: "FAQs",
      href: "/frequently-asked-questions",
    },
    {
      title: "Log Out",
      href: "/",
      onClick: () => {
        dispatch(logOut());
      },
    },
  ];

  return (
    <Dropdown onOpenChange={(open) => setIsOpen(open)}>
      <DropdownTrigger>
        <Button
          variant="flat"
          color="secondary"
          isIconOnly
          radius="none"
          className={`-mr-4 text-primary h-16 w-14 border-b-2 ${isOpen ? "border-primary bg-secondary-800" : "border-transparent hover:bg-secondary-600"}`}
        >
          <Cog8ToothIcon className="h-7" />
        </Button>
      </DropdownTrigger>
      <DropdownMenu aria-label="Static Actions" items={menuItems}>
        {({ title, href, onClick }) => (
          <DropdownItem
            key={title}
            onClick={() => {
              navigate(href);
              onClick?.();
            }}
            textValue={title}
          >
            <p className="text-base text-secondary">{title}</p>
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default SettingsMenu;
