import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Root from "./root";
import Home from "./pages/home";
import Install from "./pages/install";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import SessionStart from "./components/session/sections/start";
import { SessionRoute } from "./components/session/session";
import SessionPosition from "./components/session/sections/position";
import SessionCalibration from "./components/session/sections/calibration";
import SessionValidation from "./components/session/sections/validation";
import SessionExternal from "./components/session/sections/external";
import SessionReturn from "./components/session/sections/return";
import SessionCompletion from "./components/session/sections/complete";
import SessionError from "./components/session/sections/error";
import InstallMobileApp from "./components/install/installMobileApp";
import FAQs from "./pages/faqs";
import Rewards from "./pages/rewards";
import SignUp from "./pages/signUp";
import Tasks from "./pages/tasks";
import ResetPassword from "./pages/resetPassword";
import SessionLighting from "./components/session/sections/lighting";
import DataDeletion from "./pages/dataDeletion";
import Contact from "./components/contact/contact";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <h1>Error</h1>, // TODO: Error Element,
    children: [
      {
        path: "/",
        element: <Home />,
        children: [
          {
            path: SessionRoute.START,
            element: <SessionStart />,
          },
          {
            path: SessionRoute.LIGHTING,
            element: <SessionLighting />,
          },
          {
            path: SessionRoute.POSITION,
            element: <SessionPosition />,
          },
          {
            path: SessionRoute.CALIBRATION,
            element: <SessionCalibration />,
          },
          {
            path: SessionRoute.VALIDATION,
            element: <SessionValidation />,
          },
          {
            path: SessionRoute.EXTERNAL,
            element: <SessionExternal />,
          },
          {
            path: SessionRoute.RETURN,
            element: <SessionReturn />,
          },
          {
            path: SessionRoute.COMPLETE,
            element: <SessionCompletion />,
          },
          {
            path: SessionRoute.ERROR,
            element: <SessionError />,
          },
        ],
      },
      {
        path: "/install",
        element: <Install />,
      },
      {
        path: "/privacy-policy",
        element: <Privacy />,
      },
      {
        path: "/terms-and-conditions",
        element: <Terms />,
      },
      {
        path: "/frequently-asked-questions",
        element: <FAQs />,
      },
      {
        path: "/rewards",
        element: <Rewards />,
      },
      {
        path: "/sign-up",
        element: <SignUp />,
      },
      {
        path: "/tasks",
        element: <Tasks />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/data-deletion-request",
        element: <DataDeletion />,
      },
      {
        path: "/mobile-app",
        element: <InstallMobileApp />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
